<template>
    <b-card>
        <b-card-body>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-user"></i>
                    </span>
                    <span>{{ $t('api_account.username') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <strong>{{ item.username }}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-user-tag"></i>
                    </span>
                    <span>{{ $t('api_account.fullname') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <strong>{{ item.fullname }}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-network-wired"></i>
                    </span>
                    <span>{{ $t('api_account.domain_ip') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <strong>{{ item.domain_ip }}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-globe"></i>
                    </span>
                    <span>{{ $t('api_account.domain_name') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <strong>{{ item.domain_name }}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    <span>{{ $t('api_account.is_rest') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <i class="fas" :class="item.is_rest ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    <span>{{ $t('api_account.is_soap') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <i class="fas" :class="item.is_soap ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-font"></i>
                    </span>
                    <span>{{ $t('api_account.is_sms_utf8') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <i class="fas" :class="item.is_sms_utf8 ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="border-dark border-end">
                    <span class="min-w-2rem">
                        <i class="fas fa-thumbs-up"></i>
                    </span>
                    <span>{{ $t('api_account.is_active') }}</span>
                </b-col>
                <b-col cols="6" class="ps-3">
                    <i class="fas" :class="item.is_active ? 'fa-check-circle text-primary' : 'fa-times-circle text-warning'"></i>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "show",

    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            items: 'apiAccount/list',
            item: 'apiAccount/item'
        })
    },

    methods: {
        copyToClipboard: function () {
            navigator.clipboard.writeText(this.item.passwd);
        },
    },

    created: function () {
        if (!this.modal) {
            const action = this.items && this.items.length ? 'choose' : 'fetchOne';

            this.$store.dispatch(`apiAccount/${action}`, this.$route.params.id);
        }
    }
}
</script>

<style scoped>
    .min-w-2rem {
        display: inline-block;
        min-width: 2rem;
    }
</style>
