<template>
    <layout>
        <b-modal v-model="modal" body-bg-variant="light" size="md" centered hide-footer>
            <template #modal-title>
                #{{ item.m_id }} {{ item.fullname }}
            </template>
            <show modal></show>
        </b-modal>
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="card-title">{{ $t('api_account.page_title') }}</h4>
                    <b-button variant="success" size="sm" :to="{path: $t('routes.api_accounts_create')}">
                        <i class="fas fa-plus-square me-2"></i>
                        {{ $t('api_account.create') }}
                    </b-button>
                </div>
            </div>
            <div class="card-body p-0 pb-3 p-md-3">
                <div v-if="false" class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                    v-model="perPage"
                                    :options="pageOptions"
                                    class="form-select form-select-sm"
                                    size="sm"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                        >
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                    v-model="filter"
                                    class="form-control form-control-sm ms-2"
                                    placeholder="Search..."
                                    type="search"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :current-page="currentPage"
                        :fields="fields"
                        :items="items"
                        :per-page="perPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        head-variant="light"
                        hover
                        :busy="loading"
                        responsive="sm"
                    >
                        <template #table-busy>
                            <div class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                                </div>
                            </div>
                        </template>
                        <template #cell(is_active)="data">
                            <i :class="{'fas fa-check-circle text-success': data.value == 1, 'fas fa-times-circle text-danger': data.value == 0}"></i>
                        </template>
                        <template #cell(is_rest)="data">
                            <i :class="{'fas fa-check-circle text-success': data.value == 1, 'fas fa-times-circle text-danger': data.value == 0}"></i>
                        </template>
                        <template #cell(is_soap)="data">
                            <i :class="{'fas fa-check-circle text-success': data.value == 1, 'fas fa-times-circle text-danger': data.value == 0}"></i>
                        </template>
                        <template #cell(is_sms_utf8)="data">
                            <i :class="{'fas fa-check-circle text-success': data.value == 1, 'fas fa-times-circle text-danger': data.value == 0}"></i>
                        </template>
                        <template #cell(actions)="data">
                            <div class="no-wrap">
                                <b-button v-b-tooltip.hover.top="$t('table.view')" class="me-1" size="sm" variant="light" @click="open(data.item.m_id)">
                                    <i class="fas fa-eye"></i>
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover.top="$t('table.edit')"
                                    :to="$t('routes.api_accounts_edit').substr(0, $t('routes.api_accounts_edit').indexOf(':')) + data.item.m_id"
                                    class="me-1"
                                    size="sm"
                                    variant="info"
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover.top="$t('table.delete')"
                                    variant="danger"
                                    size="sm"
                                    @click="destroy(data.item.m_id)"
                                >
                                    <i class="fas fa-trash"></i>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </div>
<!--                <div class="row">
                    <div class="col">
                        <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                        >
                            <ul class="pagination pagination-rounded mb-0">
                                &lt;!&ndash; pagination &ndash;&gt;
                                <b-pagination
                                    v-model="currentPage"
                                    :per-page="perPage"
                                    :total-rows="rows"
                                    @change="toPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../layouts/table";
import Show from "./show";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "index",

    components: {Layout, Show},

    data() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: 'username',
            sortDesc: false,
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumSignificantDigits: 1
            }),
            modal: false
        };
    },

    computed: {
        ...mapGetters({
            items: 'apiAccount/list',
            item: 'apiAccount/item',
            loading: 'apiAccount/loading'
        }),

        rows: function () {
            return this.items && this.items.length
        },

        fields: function () {
            return [
                {
                    key: "username",
                    label: this.$t('api_account.username'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "fullname",
                    label: this.$t('api_account.fullname'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "domain_ip",
                    label: this.$t('api_account.domain_ip'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "domain_name",
                    label: this.$t('api_account.domain_name'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "is_rest",
                    label: this.$t('api_account.is_rest'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "is_soap",
                    label: this.$t('api_account.is_soap'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "is_sms_utf8",
                    label: this.$t('api_account.is_sms_utf8'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "is_active",
                    label: this.$t('api_account.is_active'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'gridjs-th',
                    tdClass: 'text-end'
                },
            ];
        },
    },

    methods: {
        open: function (id) {
            this.$store.dispatch('apiAccount/choose', id).then(() => {
                this.modal = true;
            });
        },

        destroy: function (id) {
            Swal.fire({
                title: this.$t('table.confirm'),
                text: this.$t('table.confirm_delete'),
                icon: 'warning',
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-danger btn-md me-1',
                    cancelButton: 'btn btn-primary btn-md ms-1',
                    icon: 'text-danger border-danger'
                },
                confirmButtonText: this.$t('table.yes'),
                cancelButtonText: this.$t('table.no')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('apiAccount/destroy', id).then(() => {
                        Swal.fire(
                            this.$t('request.delete_success'),
                            this.$t('table.selected_item_is_deleted'),
                            'success'
                        )
                    });
                }
            })
        },

        toPage: function (val) {
            this.page = val
            this.delayedFetch()
        },

        delayedFetch: function () {
            this.$store.dispatch('apiAccount/fetchListDelayed', {
                limit: this.perPage,
                offset: this.pager && this.pager.offset,
                page: this.page,
                sorting: {
                    column: this.sortBy,
                    desc: this.sortDesc
                }
            });
        },
    },

    watch: {
        sortBy: function () {
            this.delayedFetch();
        },
        sortDesc: function () {
            this.delayedFetch();
        },
    },

    created() {
        document.title = this.setTitle(this.$t('api_account.page_title'));
        this.$store.dispatch('apiAccount/fetchList');
    }
}
</script>

<style scoped>

</style>
